<template>
  <div style="height: 100%; overflow: hidden; display: flex; flex-direction: column">
    <v-card elevation="1" tile class="pa-5" style="display: flex; align-items: center">
      <v-icon class="mr-3" style="font-size: 12px">fa-search</v-icon>
      <v-text-field label="Search" v-model="search" autofocus></v-text-field>
      <v-btn icon small @click="search = ''">
        <v-icon class="ml-3" color="red darken-2" style="font-size: 18px">fa-sync-alt</v-icon>
      </v-btn>
    </v-card>
    <div style="flex-grow: 1; overflow: auto; padding-bottom: 6em">
      <v-btn absolute dark fab right bottom color="green darken-2" style="bottom: 3%" @click="create()">
        <v-icon>fa-user-plus</v-icon>
      </v-btn>
      <v-list subheader two-line>
        <template v-for="(item, index) in items">
          <v-subheader v-if="item.header" :key="item.header" v-text="item.header"></v-subheader>
          <v-divider v-else-if="item.divider" :key="index" :inset="item.inset"></v-divider>
          <v-list-item v-else :key="item.id">
            <v-list-item-avatar>
              <v-img :src="item.avatar"></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
              <v-list-item-subtitle v-text="item.description"></v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-icon>
              <v-icon v-if="item.role == 'admin'" color="brown">fa-user-cog</v-icon>
              <v-icon v-else color="blue">fa-user-shield</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </template>
      </v-list>
    </div>
  </div>
</template>

<script>
export default {
  name: "Organizations",
  created() {
    this.list();
    // this.gen();
  },
  data() {
    return {
      search: "",
      orgs: []
    };
  },
  computed: {
    items() {
      let list = this.orgs;
      if (this.search != "") {
        let s = this.search.toLowerCase();
        list = this.orgs.filter(org => org.title.toLowerCase().includes(s));
      }
      return list.sort((a, b) => a.title.localeCompare(b.title));
    }
  },
  methods: {
    create() {
      this.$router.push({ name: "Invite" });
    },
    async list() {
      let orgs = await this.$store.db.organizations.get();
      orgs.sort((a, b) => a.title.localeCompare(b.title));
      this.orgs = orgs;
    },
    async gen() {
      let names = ["Denver", "Broomfield", "Boulder"];
      for (let i = 0; i < names.length; i++) {
        this.$store.db.groups.add({
          descpription: "coverage area",
          title: names[i],
          devices: {
            "26131726-cfae-47cc-9df8-42388077ceaa": "ADMIN_USER",
            "e6620744-b2c4-4f4a-b3ae-edd8b9e7ee96": "ADMIN_USER"
          }
        });
      }
    }
  }
};
</script>